import Dinero from 'dinero.js';
import { isEmpty } from 'lodash';
import { Icon, Popup } from 'semantic-ui-react';

/**
 * Format number to comma seperated number
 * @param {Number} number
 */
export function formatNumber(number, decimalPlace, emptyLabel = '-') {
  if (Number(number).toString() === 'NaN' || number == null) return emptyLabel;
  return Number(number)
    .toFixed(decimalPlace)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

/**
 *
 * @param {Number} numberValue
 * @param {Number} precision
 * @param {*} param2
 */
export function formatMoney(
  numberValue,
  precision,
  { showDollarSign = true, currencySymbol = '$', emptyLabel = '0.00', showCents = true, currency = null } = {},
) {
  if (Number(numberValue).toString() === 'NaN' || numberValue == null) {
    return emptyLabel;
  }
  const parsedNumber = Number(numberValue);
  if (parsedNumber === 0) {
    return emptyLabel;
  }

  let format = `${showDollarSign ? currencySymbol : ''}0,0${showCents ? '.00' : ''}`;

  //Check to see whether the price contains a subcent value. Then display to 3dp
  const isSubCentPrice = numberValue.toString().slice(-1) !== '0';
  if(isSubCentPrice) {
    format = `${showDollarSign ? currencySymbol : ''}0,0${showCents ? '.000' : ''}`;
  }
  
  const moneyParam = currency ? { amount: parsedNumber, precision, currency } : { amount: parsedNumber, precision };
  return Dinero(moneyParam).toFormat(format);
}

export function formatRemovePlural(value) {
  return value.endsWith("s") ? value.slice(0, -1) : value;
}

export const dateParts = (x) => {
  return x.split(' ');
};

/**
 * Format number as percentage
 * @param {Number} number
 */
export function formatPercent(number, { decimalPlaces = 2, emptyLabel = '0.00%' } = {}) {
  if (Number(number).toString() === 'NaN' || number == null || number == 0) {
    return emptyLabel;
  }

  return `${(Number(number) * 100).toFixed(decimalPlaces)}%`;
}

export function formatProductMarket(productMarket) {
  if (productMarket === 'public') {
    return 'Public market';
  } else if (productMarket == 'private') {
    return 'Private market';
  } else if (productMarket == 'wholesale') {
    return 'Wholesale market';
  } else if (productMarket == 'register') {
    return 'Registry';
  } else if (productMarket == 'record') {
    return 'Angel Exchange';
  } else if (productMarket == 'group_opportunity') {
    return 'Group opportunities';
  } else {
    return '';
  }
}

export function formatAuctionType(auctionType, productName) {
  if (auctionType === 'standard') {
    return 'Secondary market';
  } else if (auctionType === 'capital_raise') {
    return 'Capital raise';
  } else if (auctionType === 'commitment_raise') {
    return 'Commitment';
  } else if (auctionType === 'exercise_issuer' || auctionType === 'exercise_issuer_oversubscription' || auctionType === 'exercise_investor' || auctionType === 'exercise_investor_oversubscription') {
    if(productName) return `Exercise: ${productName}`;
    else return 'Exercise';
  } else {
    return '';
  }
}

export function formatProductAccess(productAccess) {
  if(productAccess === 'everyone') return 'Everyone';
  if(productAccess === 'wholesale_only') return 'Wholesale only';
  if(productAccess === 'wholesale_existing') return 'Wholesale & existing holders';
  if(productAccess === 'existing_only') return 'Existing holders';
  return '';
}

export function formatName(name) {
  if(name?.length > 0) {
    if (name?.includes('Auto_generated_')) {
      return <>{name?.replace(/Auto_generated_/g, '')}</>;
    } else {
      return (
        <>
          {name}
          &nbsp;
          <Popup wide="very" trigger={<Icon name="user" color="blue" />} content="This person/entity has a Catalist account." />
        </>
      );
    }
  } else {
    return name;
  }
}

export function formatNameNewProductHolder(name, isNewProductHolder) {
  if(isNewProductHolder) {
    return (
      <>
        {name}
        &nbsp;
        <Popup wide="very" trigger={<Icon name="user plus" color="green" />} content="This person/entity is a new investor in this financial product." />
      </>
    );
  } else {
    return name;
  }
}

export function preventRedirectOnClick(event) {
  event.stopPropagation();
};

export const convertNullString = (value) => isEmpty(value) ? '' : value;


export function formatBankAccountForInput(bankAccount) {
  //Formats account to BB-bbbb-AAAAAAA-SS
  let acc = bankAccount;
  if(bankAccount && bankAccount.length === 20) {
      acc = acc.slice(1,17) + acc.slice(18).trim();
  }
  return acc;
}


export function downloadFile(url, name) {
  const link = document.createElement('a');
  link.download = name;
  link.href = url;
  link.click();
}